import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';

import { Container, Wrapper, Title, Text } from './style';

const NotFound: FC = () => (
  <Wrapper>
    <Container>
      <Title><FormattedMessage id="404.title" /></Title>
      <Text>
        <FormattedMessage id="404.text" />&nbsp;
        <Text><Link to="/" style={{'textDecoration': 'none', color: '#112631'}}><FormattedMessage id="404.home" /></Link></Text>
      </Text>
    </Container>
  </Wrapper>
);

export default NotFound;
