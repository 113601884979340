import React, { FC } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NotFound from "../components/404";

const NotFoundPage: FC = () => (
  <Layout header>
    <SEO title="404: Not found" />
    <NotFound />
  </Layout>
);

export default NotFoundPage
